import * as React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import HomeCarousel from "../components/custom/home-carousel";
import ProcessComponent from "../components/custom/process-component";
import PartnerCarousel from "../components/custom/partner-carousel";

// TODO: fix mobile nav, mobile design and mailer

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <HomeCarousel />
    <div className="main">
      <div className="section section-gray text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-8 ml-auto mr-auto">
              <h2 className="title">Domaines d'application</h2>
              <h5 className="description">
                <b>
                  Notre gamme complète de produits destinés à l’industrie des
                  composites répond à tous les besoins de notre marché. Nos
                  produits sont conformes aux normes en vigueur en matière de
                  qualité et répondent aux fortes exigences de différents
                  secteurs d’activité:
                </b>
              </h5>
              <br />
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            <div className="col-md-3">
              <div className="info">
                <div className="icon icon-danger">
                  <i className="nc-icon nc-support-17"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">
                    <b>Nautisme</b>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="info">
                <div className="icon icon-danger">
                  <i className="nc-icon nc-bus-front-12"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">
                    <b>Transport</b>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="info">
                <div className="icon icon-danger">
                  <i className="nc-icon nc-satisfied"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">
                    <b>Sanitaire</b>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="info">
                <div className="icon icon-danger">
                  <i className="nc-icon nc-palette"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">
                    <b>Art & loisir</b>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            <div className="col-md-3">
              <div className="info">
                <div className="icon icon-danger">
                  <i className="nc-icon nc-world-2"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">
                    <b>Agriculture</b>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="info">
                <div className="icon icon-danger">
                  <i className="nc-icon nc-istanbul"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">
                    <b>Bâtiment</b>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="info">
                <div className="icon icon-danger">
                  <i className="nc-icon nc-user-run"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">
                    <b>Sport</b>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="info">
                <div className="icon icon-danger">
                  <i className="nc-icon nc-atom"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">
                    <b>Electrique</b>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container tim-container">
          <div className="container">
            <div className="motto text-center">
              <h1>Notre Expertise</h1>
              <h3>
                Dotée d'une forte expérience, notre équipe est capable de vous
                fournir une solution à tous vos besoins
              </h3>
              <div style={{ height: "3em" }}></div>
            </div>
          </div>
          <ProcessComponent />
        </div>
      </div>

      <div className="section section-gray text-center">
        <div className="container">
          <PartnerCarousel />
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  </Layout>
);

export const Head = () => <Seo title="Home" />;

export default IndexPage;
