import * as React from "react";
import { Carousel } from "react-bootstrap";
import quimidroga from "../../images/logos/quimidroga.png";
import euromere from "../../images/logos/euromere.png";
import sicomin from "../../images/logos/sicomin.png";
import gazechim from "../../images/logos/gazechim.png";
import diab from "../../images/logos/diab.png";
import thermhex from "../../images/logos/thermhex.png";

const PartnerCarousel = (props) => (
  <Carousel
    fade
    interval={2000}
    prevLabel={null}
    nextLabel={null}
    prevIcon={
      <div className="icon-wrapper-prev">
        <span aria-hidden="true" className="carousel-control-prev-icon" />
      </div>
    }
    nextIcon={
      <div className="icon-wrapper-next">
        <span aria-hidden="true" className="carousel-control-next-icon" />
      </div>
    }
  >
    <Carousel.Item>
      <div className="partner-container">
        <img
          style={{ maxWidth: "250px" }}
          className="img-fluid w-100"
          src={quimidroga}
          alt="Logo Quimidroga"
        />
      </div>
    </Carousel.Item>
    <Carousel.Item>
      <div className="partner-container">
        <img
          style={{ maxWidth: "170px" }}
          className="img-fluid w-100"
          src={euromere}
          alt="Logo Euromere"
        />
      </div>
    </Carousel.Item>
    <Carousel.Item>
      <div className="partner-container">
        <img
          style={{ maxWidth: "250px" }}
          className="img-fluid w-100"
          src={sicomin}
          alt="Logo Sicomin"
        />
      </div>
    </Carousel.Item>
    <Carousel.Item>
      <div className="partner-container">
        <img
          style={{ maxWidth: "250px" }}
          className="img-fluid w-100"
          src={gazechim}
          alt="Logo Groupe Gazechim"
        />
      </div>
    </Carousel.Item>
    <Carousel.Item>
      <div className="partner-container">
        <img
          style={{ maxWidth: "250px" }}
          className="img-fluid w-100"
          src={diab}
          alt="Logo DIAB"
        />
      </div>
    </Carousel.Item>
    <Carousel.Item>
      <div className="partner-container">
        <img
          style={{ maxWidth: "250px" }}
          className="img-fluid w-100"
          src={thermhex}
          alt="Logo Thermex"
        />
      </div>
    </Carousel.Item>
  </Carousel>
);
export default PartnerCarousel;
